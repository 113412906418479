<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="
        $vuetify.breakpoint.smAndDown
          ? setDrawer(!drawer)
          : $emit('input', !value)
      "
    >
      <v-icon v-if="value"> mdi-menu </v-icon>

      <v-icon v-else> mdi-menu </v-icon>
    </v-btn>

    <v-breadcrumbs :items="breadcrumb" />

    <v-spacer />

    <!--v-text-field
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 165px;"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:append-outer
      >
        <v-btn
          class="mt-n2"
          elevation="1"
          fab
          small
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field-->

    <div class="mx-3" />

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <!--template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <template v-for="(p, i) in profile">
          <v-divider
            v-if="p.divider"
            :key="`divider-${i}`"
            class="mb-2 mt-2"
          />

          <app-bar-item
            v-else
            :key="`item-${i}`"
            :to="p.to"
          >
            <v-list-item-title v-text="p.title" />
          </app-bar-item>
        </template>
      </v-list-->
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from 'vuetify/lib'

// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render (h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(VListItem, {
                attrs: this.$attrs,
                class: {
                  'black--text': !hover,
                  'white--text primary elevation-12': hover,
                },
                props: {
                  activeClass: '',
                  dark: hover,
                  link: true,
                  ...this.$attrs,
                },
              }, this.$slots.default)
            }
          }
        })
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    breadcrumb:[],
    profile: [
      { title: "Perfil", to: "/perfil/edit" },
      { divider: true },
      { title: "Logout", to: "/perfil/logout" }
    ]
  }),

  mounted() {
    this.assignBreadcrumb();
  },

  computed: {
    ...mapState(["drawer"]),
  },

  watch: {

    //a view de relatorio altera a variavel no store, e esse watch observa a mudança e monta breadcrumb
    '$store.state.watchBreadcrumb': {
      deep: true,
      handler(newVal) {
        this.breadcrumb = this.$route.meta.breadcrumb;
      }
    },

    '$route'(){
      this.assignBreadcrumb()
    }
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),

    assignBreadcrumb(){
      this.breadcrumb = this.$route.meta.breadcrumb(this.$route);
    }
  }
};
</script>

<style >
.v-breadcrumbs li {
  font-size: 1.25rem !important;
}
</style>